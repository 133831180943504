import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Center,
  Spinner,
} from '@chakra-ui/react'
import { useLanguageContext } from '../../context/locale.context'
import { timeAgo } from './../../utils/common.util'

const ReporteeDisplay = ({ headers, data, onSelect, isLoading }) => {
  const {
    state: { locale },
  } = useLanguageContext()

  if (isLoading)
    return (
      <Center p="10">
        <Spinner size="lg" color="brand.900" />
      </Center>
    )

  return (
    <>
      {data?.length > 0 ? (
        <TableContainer
          maxH="70vh"
          overflowY="scroll"
          overflowX="visible"
          pr={3}
          mt={5}
          sx={{
            '::-webkit-scrollbar': {
              width: '3px',
            },
            '::-webkit-scrollbar-thumb': {
              outline: `1px solid ${localStorage.getItem('color')}`,
              borderRadius: '50px',
              border: 'solid 3px transparent',
              backgroundClip: 'content-box',
              WebkitBackgroundClip: 'content-box',
            },
          }}
        >
          <Table variant="stickyHeaders">
            <Thead>
              <Tr>
                {headers.map((item, i) => {
                  return <Th key={i}>{locale[item]}</Th>
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((item, i) => {
                return (
                  <Tr
                    key={i}
                    cursor="pointer"
                    _hover={{
                      fontWeight: '600',
                    }}
                    onClick={() => {
                      onSelect(item)
                    }}
                  >
                    <Td>{i + 1}</Td>
                    <Td>{item.operating_asset_first_name}</Td>
                    <Td>{item.workforce_name}</Td>
                    <Td>{item.account_name}</Td>
                    <Td>{timeAgo(item.asset_last_seen_datetime)}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Center>
          <h5>{locale["You Don't Have Any Reportees"]}</h5>
        </Center>
      )}
    </>
  )
}

export default ReporteeDisplay
