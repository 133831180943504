import { useEffect } from 'react'
import { useLoadBotBetaAssetMapping } from '../../hooks/asset.hooks'
import { useAuthContext } from './../../context/auth.context'
import AssetARPScoreDisplay from './assetARPScoreDisplay'

const headers = [
  'S.No',
  'Bot Name',
  'Status Transactions Count',
  'BPI',
  'RPI',
  'CPI',
  'IOF',
]

const SelfARPScores = () => {
  const {
    mutate: loadBotBetaAssetMapping,
    data: botBetaAssetData,
    isLoading: loadingBotBetaAsset,
  } = useLoadBotBetaAssetMapping()

  const {
    state: { authData },
  } = useAuthContext()

  useEffect(() => {
    loadBotBetaAssetMapping({
      target_asset_id: authData.asset_id,
      flag: 2,
      start_from: 0,
      limit_value: 50,
      bot_id: 0,
    })
  }, [authData, loadBotBetaAssetMapping])

  return (
    <>
      <AssetARPScoreDisplay
        headers={headers}
        data={botBetaAssetData}
        isLoading={loadingBotBetaAsset}
      />
    </>
  )
}

export default SelfARPScores
