/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import {
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Icon,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Skeleton,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import FilterItem from '../../../components/dashboard/filter'
import { useDashboardContext } from '../../../context/dashboard.context'
import { dashboardConst, sharedConst } from '../../../utils/action.constant'
import moment from 'moment'
import { BsPlus } from 'react-icons/bs'
import { useMutation } from 'react-query'
import {
	loadVeriticalFilterOptions,
	useLoadApplication,
	useLoadFilters,
	useLoadSubApplication,
	useLoadCluster,
	useLoadSegment,
	useLoadAssetTag1,
	useLoadAssetTag2,
	useLoadAssetTag3,
	useLoadAssetTagType1,
	useLoadAssetTagType2,
	useLoadAssetTagType3,
	useLoadWorkEfficiencyWorkflow,
	useLoadWorkEfficiencyCustomer,
	useLoadWorkEfficiencyIndustry,
	useLoadCategory,
	useLoadWorkforce,
	useLoadOppurtunity,
	useLoadBuilding,
	useLoadFloor,
	useLoadCreator,
	useLoadTBCreator,
	useLoadAccount,
	useLoadFamily,
	useLoadProduct,
	useLoadCampaign,
	useLoadVeriticals,
	useLoadKPICategories,
	useLoadKPIProducts,
	useLoadKPITypes,
	useClassifyRoleList,
	useRolesBasedOnUser,
	useVerticalBasedOnRole,
	useClusterBasedOnRole,
} from '../../../hooks/dashboard.hooks'
import {
	FILTER_FLAG,
	FILTER_ID,
	HALF_YEARLY,
	MONTHS,
	QUATER,
} from '../../../utils/filter.constant'
import { checkFieldDisabled } from '../../../utils/dashboard.utils'
import {
	getStartDateOfMonth,
	getStartOfNextMonth,
	getStartOfRealtime,
	getEndDateOfMonth,
	getEndOfDay,
	getCurrentDate,
	getStartofCurrentQuater,
	getEndofCurrentQuater,
	getStartOfPastMonth,
	getEndOfPastMonth,
	getStartofPastQuater,
	getEndofPastQuater,
	getStartofNextQuater,
	getEndofNextQuater,
	getEndOfNextMonth,
} from '../../../utils/date.utils'
import { useAuthContext } from '../../../context/auth.context'
import { debounce } from 'lodash'
import { useLanguageContext } from '../../../context/locale.context'
import { useLoadProductCategories } from '../../../hooks/form.hooks'
import { useParams, useHistory } from 'react-router-dom'
import FormPanel from '../../form-panel'

import { SmartFormContextProvider } from '../../../context/smart-form.context'
import SmartFormPanel from '../../form-panel/smart-form-panel'
//import { WIDGET_WORKSPACE_MODE } from '../../../utils/widget-constants'

function FilterView({ widgetView, onWidgetViewChange, from = 'dashboard' }) {
	const history = useHistory()
	const view = {
		CATEGORY_SELECTOR: 'CATEGORY_SELECTOR',
		ORIGIN_FORM: 'ORIGIN_FORM',
		ERROR_VIEW: 'ERROR_VIEW',
	}

	const {
		state: { queryFilters, filterOptions, activeFilterOption },
		dispatch: dashboardDispatch,
	} = useDashboardContext()

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const toast = useToast()
	const params = useParams()
	const [isAccess, setIsAccess] = useState(true)
	const [closeDrawer, setCloseDrawer] = useState(false)
	const [isRomsOpen, setOpenRomsView] = useState(false)
	const [dataViewPeriod, setDataViewPeriod] = useState(6)
	const defaultOption = { value: 0, label: locale['All'] }

	const onFilterOptionLoadSuccess = data => {
		setFilterOptions(data.key, data.optionList)
		setQueryFilterValue(data.selectkey, data.optionList[0])
		if (params?.params && data.selectkey === 'selectedApplication') {
			let app_params = {
				label: JSON.parse(params.params)?.app_name,
				value: JSON.parse(params.params)?.app_id,
				field_edit_enabled: data.optionList.filter(
					item => item.value === JSON.parse(params.params)?.app_id
				)[0].field_edit_enabled,
			}
			setQueryFilterValue('selectedApplication', app_params)
		}
		if (
			params?.params &&
			data.selectkey === 'selectedSubApplication' &&
			queryFilters?.selectedApplication?.label ===
				JSON.parse(params?.params)?.app_name
		) {
			let currSubApp = data.optionList.find(
				item => item.value === JSON.parse(params.params).value
			)
			let sub_params = {
				label: JSON.parse(params.params).label,
				value: JSON.parse(params.params).value,
				is_card_layout_enabled: JSON.parse(params.params)
					.is_card_layout_enabled,
				add_new_file_enabled: currSubApp?.add_new_file_enabled,
			}
			setQueryFilterValue('selectedSubApplication', sub_params)
		}
	}

	const [panelView, setpanelView] = useState(view.CATEGORY_SELECTOR)
	const [selectedForm, setSelectedForm] = useState(null)

	const { mutate: loadApp } = useLoadApplication()
	const { mutate: loadCitysData } = useLoadProductCategories()
	const {
		mutate: loadFilters,
		isLoading: isFilterLoading,
		data: filterList,
		status: statusFilters,
	} = useLoadFilters()
	const { mutate: loadSubApplication } = useLoadSubApplication()
	const { mutate: loadCluster, data: clusterList } = useLoadCluster()
	const { mutate: loadAssetTag1 } = useLoadAssetTag1()
	const { mutate: loadAssetTag2 } = useLoadAssetTag2()
	const { mutate: loadAssetTag3 } = useLoadAssetTag3()
	const { mutate: loadAssetTagType1 } = useLoadAssetTagType1()
	const { mutate: loadAssetTagType2 } = useLoadAssetTagType2()
	const { mutate: loadAssetTagType3 } = useLoadAssetTagType3()
	const { mutate: loadWorkEfficiencyWorkflow } = useLoadWorkEfficiencyWorkflow()
	const { mutate: loadWorkEfficiencyIndustry } = useLoadWorkEfficiencyIndustry()
	const { mutate: loadWorkEfficiencyCustomer } = useLoadWorkEfficiencyCustomer()

	const { mutate: loadVerticals } = useLoadVeriticals()
	const { mutate: loadSegment } = useLoadSegment()
	const { mutate: loadCategory } = useLoadCategory()
	const { mutate: loadWorkforce } = useLoadWorkforce()
	const { mutate: loadCampaign } = useLoadCampaign()
	const { mutate: loadOppurtunity } = useLoadOppurtunity()
	const { mutate: loadBuilding } = useLoadBuilding()
	const { mutate: loadFloor } = useLoadFloor()
	const { mutate: loadCreator } = useLoadCreator()
	const { mutate: loadTBCreator } = useLoadTBCreator()
	const { mutate: loadFamily } = useLoadFamily()
	const { mutate: loadProduct } = useLoadProduct()
	const { mutate: loadAccount } = useLoadAccount()
	const { mutate: loadKPIProducts } = useLoadKPIProducts()
	const { mutate: loadKPICategories } = useLoadKPICategories()
	const { mutate: loadKPITypes } = useLoadKPITypes()
	const { mutate: loadClassifyRoles } = useClassifyRoleList()
	//Below 3 mutations are specific to Periodic Summary in Dashboard
	const { mutate: loadRolesBasedOnUser } = useRolesBasedOnUser()
	const { mutate: loadVerticalBasedOnUser } = useRolesBasedOnUser()
	const { mutate: loadClusterBasedOnUser } = useRolesBasedOnUser()

	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

	const { mutate: mutateVerticalListForLeaderBoard } = useMutation(
		payload => loadVeriticalFilterOptions(payload),
		{
			onSuccess: (res, payload) => {
				onFilterOptionLoadSuccess(res)
			},
			onError: async err => {
				console.log(err)
			},
		}
	)

	const { mutate: mutateClusterListForLeaderBoard } = useMutation(
		payload => loadVeriticalFilterOptions(payload),
		{
			onSuccess: (res, payload) => {
				if (queryFilters?.selectedSubApplication?.value === 221) {
					res.optionList.unshift({ value: 0, label: 'All' })
				}

				onFilterOptionLoadSuccess(res)
			},
			onError: async err => {
				console.log(err)
			},
		}
	)

	useEffect(() => {
		if (!!authData) {
			setDataViewPeriod(Number(authData.organization_data_view_period))
			dashboardDispatch({
				type: dashboardConst.CLEAR_QUERY_FILTERS,
				filterName: '',
				value: {},
			})
		}
	}, [authData])

	useEffect(() => {
		loadApp(
			{},
			{
				onSuccess: onFilterOptionLoadSuccess,
			}
		)
	}, [authData])

	useEffect(() => {
		if (!!queryFilters.selectedApplication) {
			loadSubApplication(fetchOptionsParams(FILTER_FLAG.SUB_APPLICATION), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedApplication])

	useEffect(() => {
		if (!!queryFilters.selectedSubApplication) {
			loadFilters(
				queryFilters.selectedSubApplication,
				queryFilters.selectedOrganization?.value
			)

			setQueryFilterValue(
				'selectedBuilding',
				filterOptions['buildingList']?.[0]
			)
			/*setQueryFilterValue(
				'selectedWorkforce',
				filterOptions['workforceList']?.[0]
			)*/
		}
	}, [queryFilters.selectedSubApplication])

	useEffect(() => {
		if (!!filterList) {
			loadStaticOptions()
			loadWorkflows()

			loadAssetTagType1(fetchOptionsParams(FILTER_FLAG.ASSET_TAG_TYPE1), {
				onSuccess: onFilterOptionLoadSuccess,
			})
			loadAssetTagType2(fetchOptionsParams(FILTER_FLAG.ASSET_TAG_TYPE2), {
				onSuccess: onFilterOptionLoadSuccess,
			})
			loadAssetTagType3(fetchOptionsParams(FILTER_FLAG.ASSET_TAG_TYPE3), {
				onSuccess: onFilterOptionLoadSuccess,
			})

			loadOppurtunity(fetchOptionsParams(FILTER_FLAG.OPPURTUNITY), {
				onSuccess: onFilterOptionLoadSuccess,
			})

			//Make new api call to fetch role,Vertical ,cluster list based on assetId for periodic summary
			//   if (!!queryFilters.selectedSubApplication && queryFilters.selectedSubApplication?.value === 222) {
			//     loadRolesBasedOnUser(fetchOptionsParams(1), {
			//       onSuccess: onFilterOptionLoadSuccess
			//     })
			//     loadVerticalBasedOnUser(fetchOptionsParams(2), {
			//       onSuccess: onFilterOptionLoadSuccess
			//     })
			//     loadClusterBasedOnUser(fetchOptionsParams(3), {
			//       onSuccess: (res) => {
			//          res.optionList.unshift({ value: 0, label: 'All' })
			//         onFilterOptionLoadSuccess(res)
			//       },
			//     })
			//   }
			if (
				!!queryFilters.selectedSubApplication &&
				queryFilters.selectedSubApplication?.value === 221
			) {
				loadRolesBasedOnUser(fetchOptionsParams(FILTER_FLAG.CLASSIFY_ROLES), {
					onSuccess: onFilterOptionLoadSuccess,
				})

				//Verticals call for leaderBoard needs direct mutation to set data in dropdown, hence using mutateVerticalListForLeaderBoard

				mutateVerticalListForLeaderBoard({
					...fetchOptionsParams(FILTER_FLAG.VERTICAL),
				})

				mutateClusterListForLeaderBoard({
					...fetchOptionsParams(FILTER_FLAG.CLUSTER),
				})
			} else {
				loadClassifyRoles(fetchOptionsParams(FILTER_FLAG.CLASSIFY_ROLES), {
					onSuccess: onFilterOptionLoadSuccess,
				})
				loadVerticals(fetchOptionsParams(FILTER_FLAG.VERTICAL), {
					onSuccess: onFilterOptionLoadSuccess,
				})
				loadCluster(fetchOptionsParams(FILTER_FLAG.CLUSTER), {
					onSuccess: onFilterOptionLoadSuccess,
				})
			}

			if (
				!!queryFilters.selectedSubApplication &&
				[223, 224].includes(queryFilters.selectedSubApplication.value)
			) {
				//load kpi products and categories
				loadKPIProducts(
					{},
					{
						onSuccess: onFilterOptionLoadSuccess,
					}
				)
				loadKPICategories(
					{},
					{
						onSuccess: onFilterOptionLoadSuccess,
					}
				)
			}
			if (isAccess) {
				loadSegment(fetchOptionsParams(FILTER_FLAG.SEGMENT), {
					onSuccess: onFilterOptionLoadSuccess,
				})
				loadCategory(fetchOptionsParams(FILTER_FLAG.CATEGORY), {
					onSuccess: onFilterOptionLoadSuccess,
				})
				loadWorkforce(fetchOptionsParams(FILTER_FLAG.WORKFORCE_TAG), {
					onSuccess: onFilterOptionLoadSuccess,
				})
				loadCampaign(fetchOptionsParams(FILTER_FLAG.CAMPAIGN), {
					onSuccess: onFilterOptionLoadSuccess,
				})
			}
			if (authData?.organization_flag_enable_resource_filter !== 0) {
				loadCreator(fetchOptionsParams(FILTER_FLAG.CREATOR), {
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})
			}
		}
	}, [filterList])

	useEffect(() => {
		loadKPITypes(fetchKpiTypesParams(), {
			onSuccess: onFilterOptionLoadSuccess,
		})

		if ([2103].includes(queryFilters?.selectedSubApplication?.value)) {
			if (queryFilters && queryFilters?.selectedCluster?.value === 0) {
				loadCitysData(
					{
						tag_type_category_id: 6,
						tag_type_id: 2105,
					},
					{
						onSuccess: data => {
							setFilterOptions('clusterList', [
								{ value: 0, label: 'National' },
								...data,
							])
						},
					}
				)
			}
		}
	}, [
		queryFilters.selectedKPICategory,
		queryFilters.selectedKPIProduct,
		queryFilters.selectedWorkforceTag,
		queryFilters.selectedCluster,
	])

	useEffect(() => {
		if (!!clusterList) {
			if (clusterList.optionList[0].label === 'NA') {
				setIsAccess(false)
				const defaultCluster = {
					value: !!authData && authData.cluster_tag_id,
					label: !!authData && authData.cluster_tag_name,
				}
				const defaultBuilding = {
					value: !!authData && authData.account_id,
					label: !!authData && authData.account_name,
				}
				const defaultFloor = {
					value: !!authData && authData.workforce_id,
					label: !!authData && authData.workforce_name,
				}
				const defaultCreator = {
					value: !!authData && authData.asset_id,
					label: !!authData && authData.operating_asset_first_name,
					...authData,
				}
				setQueryFilterValue('selectedCluster', defaultCluster)
				setQueryFilterValue('selectedCreator', defaultCreator)
				setQueryFilterValue('selectedSegment', defaultOption)
				setQueryFilterValue('selectedCategory', defaultOption)
				setQueryFilterValue('selectedWorkforceTag', defaultOption)
				setQueryFilterValue('selectedWorkforce', defaultFloor)
				//setQueryFilterValue('selectedCampaign', defaultOption);
				if (![223, 224].includes(queryFilters.selectedSubApplication?.value)) {
					setQueryFilterValue('selectedBuilding', defaultBuilding)
				}
			}
		}
	}, [clusterList])

	useEffect(() => {
		if (
			!!queryFilters.selectedCluster &&
			isAccess &&
			!!queryFilters.selectedSubApplication &&
			queryFilters.selectedSubApplication.value !== 222
		) {
			if (queryFilters.selectedCluster.value === -1) {
				// setQueryFilterValue('selectedBuilding', { value: -1, label: 'My Circles' });
				onFilterOptionLoadSuccess({
					key: 'buildingList',
					selectedKey: 'selectedBuilding',
					optionList: [],
				})
				/*onFilterOptionLoadSuccess({
					key: 'workforceList',
					selectedKey: 'selectedWorkforce',
					optionList: [],
				})*/
			} else {
				loadBuilding(fetchOptionsParams(FILTER_FLAG.GEOGRAPHY), {
					onSuccess: data => {
						// if(data.optionList.length > 1 && !data.optionList.find(item => item.value === 0)){
						//   data.optionList.unshift({ value: -1, label: 'My Circles' })
						// }
						onFilterOptionLoadSuccess(data)
					},
				})
				/*loadFloor(fetchOptionsParams(FILTER_FLAG.WORKFORCE),{
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})*/
			}
		}
	}, [queryFilters.selectedCluster])

	useEffect(() => {
		if (
			!!queryFilters.selectedBuilding &&
			queryFilters.selectedBuilding.value !== -1 &&
			!!queryFilters.selectedWorkforce &&
			queryFilters.selectedWorkforce.value !== -1
		) {
			if (isAccess) {
				loadCreator(fetchOptionsParams(FILTER_FLAG.CREATOR), {
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})
			}
		} else {
			onFilterOptionLoadSuccess({
				key: 'creatorList',
				selectedKey: 'selectedCreator',
				optionList: [],
			})
		}
	}, [queryFilters.selectedBuilding, queryFilters.selectedWorkforce])

	useEffect(() => {
		if (
			!!queryFilters.selectedBuilding &&
			queryFilters.selectedBuilding.value !== -1
		) {
			if (isAccess) {
				loadFloor(fetchOptionsParams(FILTER_FLAG.WORKFORCE), {
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})
			}
		} else {
			onFilterOptionLoadSuccess({
				key: 'workforceList',
				selectedKey: 'selectedWorkforce',
				optionList: [],
			})
		}
	}, [queryFilters.selectedBuilding])

	useEffect(() => {
		if (
			!!queryFilters.selectedBuilding &&
			queryFilters.selectedBuilding.value !== -1
		) {
			if (
				isAccess &&
				[223, 224, 1931, 148, 220, 222, 1932, 1933, 1934, 1935].includes(
					queryFilters.selectedSubApplication?.value
				)
			) {
				loadTBCreator(fetchOptionsParams(FILTER_FLAG.TBCREATOR), {
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})
			}
		} else {
			onFilterOptionLoadSuccess({
				key: 'creatorList',
				selectedKey: 'selectedCreator',
				optionList: [],
			})
		}
	}, [
		queryFilters.selectedBuilding,
		queryFilters.selectedWorkforce,
		queryFilters.selectedWorkforceTag,
		queryFilters.selectedTag1,
		queryFilters.selectedTagType1,
		queryFilters.selectedYear,
		queryFilters.selectedSipSegment,
		queryFilters.selectedPeriod,
		queryFilters.selectedPeriodType,
	])

	//use effect for sip widgets
	useEffect(() => {
		if (
			!!queryFilters.selectedBuilding &&
			queryFilters.selectedBuilding.value !== -1
		) {
			if (
				[1931, 148, 220, 222, 1932, 1933, 1934, 1935].includes(
					queryFilters.selectedSubApplication?.value
				)
			) {
				setIsAccess(true)
				loadTBCreator(fetchOptionsParams(FILTER_FLAG.TBCREATOR), {
					onSuccess: data => {
						onFilterOptionLoadSuccess(data)
					},
				})
			}
		} else {
			onFilterOptionLoadSuccess({
				key: 'creatorList',
				selectedKey: 'selectedCreator',
				optionList: [],
			})
		}
	}, [
		queryFilters.selectedBuilding,
		queryFilters.selectedWorkforce,
		queryFilters.selectedWorkforceTag,
		queryFilters.selectedTag1,
		queryFilters.selectedTagType1,
		queryFilters.selectedYear,
		queryFilters.selectedSipSegment,
		queryFilters.selectedPeriod,
		queryFilters.selectedPeriodType,
	])

	useEffect(() => {
		if (
			!!queryFilters.selectedSubApplication &&
			queryFilters.selectedSubApplication.value !== 222
		) {
			loadVerticals(fetchOptionsParams(FILTER_FLAG.VERTICAL), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedWorkforceTag])

	useEffect(() => {
		if (
			!!queryFilters.selectedSubApplication &&
			queryFilters.selectedSubApplication.value === 222
		) {
			if (
				!!queryFilters.selectedSipSegment &&
				queryFilters.selectedSipSegment.value !== 358
			) {
				setIsAccess(true)
				loadClusterBasedOnUser(fetchOptionsParams(3), {
					onSuccess: res => {
						//  res.optionList.unshift({ value: 0, label: 'All' })
						onFilterOptionLoadSuccess(res)
					},
				})
			} else {
				loadVerticalBasedOnUser(fetchOptionsParams(2), {
					onSuccess: res => {
						onFilterOptionLoadSuccess(res)
					},
				})
			}
			loadRolesBasedOnUser(fetchOptionsParams(1), {
				onSuccess: res => {
					onFilterOptionLoadSuccess(res)
				},
			})
		}
	}, [
		queryFilters.selectedCreator,
		queryFilters.selectedPeriod,
		queryFilters.selectedSipSegment,
	])

	useEffect(() => {
		if (
			!!queryFilters.selectedSubApplication &&
			queryFilters.selectedSubApplication.value === 221
		) {
			if (
				!!queryFilters.selectedSipSegment &&
				queryFilters.selectedSipSegment.value !== 358
			) {
				setIsAccess(true)
			}
			loadRolesBasedOnUser(fetchOptionsParams(FILTER_FLAG.CLASSIFY_ROLES), {
				onSuccess: onFilterOptionLoadSuccess,
			})

			//Verticals call for leaderBoard needs direct mutation to set data in dropdown, hence using mutateVerticalListForLeaderBoard

			mutateVerticalListForLeaderBoard({
				...fetchOptionsParams(FILTER_FLAG.VERTICAL),
			})

			mutateClusterListForLeaderBoard({
				...fetchOptionsParams(FILTER_FLAG.CLUSTER),
			})
		}
	}, [
		queryFilters.selectedSipSegment,
		queryFilters.selectedPeriod,
		queryFilters.selectedPeriodType,
	])

	useEffect(() => {
		if (
			!!queryFilters.selectedTagType1 &&
			queryFilters.selectedTagType1.value !== -1
		) {
			loadAssetTag1(fetchOptionsParams(FILTER_FLAG.ASSET_TAG1), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedTagType1])

	useEffect(() => {
		if (
			!!queryFilters.selectedTagType2 &&
			queryFilters.selectedTagType2.value !== -1
		) {
			loadAssetTag2(fetchOptionsParams(FILTER_FLAG.ASSET_TAG2), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedTagType2])

	useEffect(() => {
		if (
			!!queryFilters.selectedTagType3 &&
			queryFilters.selectedTagType3.value !== -1
		) {
			loadAssetTag3(fetchOptionsParams(FILTER_FLAG.ASSET_TAG3), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedTagType3])

	useEffect(() => {
		if (!!queryFilters.selectedCategory) {
			loadFamily(fetchOptionsParams(FILTER_FLAG.FAMILY), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedCategory])

	useEffect(() => {
		if (!!queryFilters.selectedFamily) {
			loadProduct(fetchActivityCategoryParams(FILTER_FLAG.PRODUCT), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedFamily])

	useEffect(() => {
		if (!!queryFilters.selectedSegment) {
			loadAccount(fetchActivityCategoryParams(FILTER_FLAG.ACCOUNT), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}
	}, [queryFilters.selectedSegment])

	// useEffect(() => {
	// 	if (queryFilters?.selectedSubApplication?.add_new_file_enabled === 1) {
	// 		setShowAddButton(true)
	// 	} else {
	// 		setShowAddButton(false)
	// 	}
	// }, [queryFilters])

	function setFilterOptions(key = '', filter = []) {
		return dashboardDispatch({
			type: dashboardConst.SET_FILTER_OPTIONS,
			key,
			filter,
		})
	}

	function setQueryFilterValue(filterName, value) {
		return dashboardDispatch({
			type: dashboardConst.UPDATE_QUERY_FILTERS,
			filterName,
			value,
		})
	}

	function fetchOptionsParams(flag = 0, search = '') {
		const {
			selectedApplication: tagId,
			selectedSubApplication: tagTypeId,
			selectedCluster: clusterId,
			selectedBuilding: buildingId,
			selectedCategory: categoryId,
			selectedTag1: subVertical,
			selectedWorkforce: workforceId,
			selectedTagType1,
			selectedTagType2,
			selectedTagType3,
			selectedWorkforceTag: segment,
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		} = queryFilters

		let params = {
			flag: flag,
			tag_id: tagId && tagId.value,
			tag_type_id: tagTypeId && tagTypeId.value,
			cluster_id: clusterId && clusterId.value,
			building_id: buildingId && buildingId.value,
			category_id: categoryId && categoryId.value,
			workforce_id: workforceId && workforceId.value,
		}

		if (
			!!queryFilters.selectedSubApplication &&
			// queryFilters.selectedSubApplication.value === 111
			flag === FILTER_FLAG.CAMPAIGN
		) {
			const campaignFilter = filterList.find(
				filter => filter.filter_type_id === 57
			)
			if (!!campaignFilter) {
				const { target_activity_type_id, target_tag_type_id } = campaignFilter
				params = {
					...params,
					target_tag_type_id,
					target_activity_type_id,
					search,
				}
			}
		}
		if (segment && flag === FILTER_FLAG.VERTICAL) {
			//to populate data under vertical in LeaderBoard
			if (tagTypeId && tagTypeId?.value === 221) {
				params = {
					...params,
					workforce_tag_id: 358,
					tag_type_id: 173,
					tag_type_name: tagTypeId && tagTypeId.label,
					period_type_id: queryFilters?.selectedPeriod?.value || 1,
					timeline_id: queryFilters?.selectedPeriodType?.value,
				}
			} else {
				params = {
					...params,
					workforce_tag_id: segment?.value || 0,
					tag_type_id: tagTypeId && tagTypeId.value,
					tag_type_name: tagTypeId && tagTypeId.label,
				}
			}
		} else if (segment && flag === FILTER_FLAG.CLUSTER) {
			//to populate data under cluster in LeaderBoard
			if (tagTypeId && tagTypeId?.value === 221) {
				params = {
					...params,
					workforce_tag_id:
						queryFilters?.selectedSipSegment?.value === 184 ? 184 : 185,
					tag_type_id: 173,
					tag_type_name: tagTypeId && tagTypeId.label,
					flag: FILTER_FLAG.VERTICAL,
					selectedSubApplication: queryFilters?.selectedSubApplication?.value,
				}
			}
		} else if (selectedTagType1 && flag === FILTER_FLAG.ASSET_TAG1) {
			params = {
				...params,
				tag_type_id: selectedTagType1.value,
				base_tag_type_id: tagTypeId,
			}
		} else if (selectedTagType2 && flag === FILTER_FLAG.ASSET_TAG2) {
			params = {
				...params,
				tag_type_id: selectedTagType2.value,
				base_tag_type_id: tagTypeId,
			}
		} else if (selectedTagType3 && flag === FILTER_FLAG.ASSET_TAG3) {
			params = {
				...params,
				tag_type_id: selectedTagType3.value,
				base_tag_type_id: tagTypeId,
			}
		} else if (flag === FILTER_FLAG.CLASSIFY_ROLES) {
			if (queryFilters?.selectedSubApplication?.value === 221) {
				params = {
					...params,
					flag: 4,
					workforce_tag_id: queryFilters?.selectedSipSegment?.value,
					vertical_tag_id: 0,
					cluster_id: 0,
					period_type_id: queryFilters?.selectedPeriod?.value || 1,
					financial_year: !!selectedYear
						? selectedYear.value.replaceAll(' ', '')
						: '',
					asset_type_id: 0,
				}
			} else {
				params = {
					asset_type_category_id: 3,
					asset_type_flag_frontline: 0,
					asset_type_flag_sip_target: -1,
					asset_type_id: 0,
					datetime_log: moment().format('YYYY-MM-DD hh:mm:ss'),
					level_id: 1,
					page_limit: 50,
					page_start: 0,
					flag: 7,
				}
			}
		} else if (flag === FILTER_FLAG.TBCREATOR) {
			params = {
				account_id: buildingId?.value || 0,
				workforce_type_id: 0,
				workforce_id: 0,
				workforce_tag_id:
					queryFilters?.selectedSubApplication?.value === 1932
						? 358
						: segment?.value || 0,
				cluster_tag_id: clusterId?.value || 0,
				vertical_tag_id: selectedTagType1?.value || 0,
				subvertical_tag_id: subVertical?.value || 0,
				flag: flag,
				sort_flag: 0,
				start_from: 0,
				limit_value: 500,
				is_search: search !== '' ? 1 : 0,
				search_string: search,
				timeline_id: selectedPeriodType?.value || 0,
				period_type_id: selectedPeriod?.value || 1,
				financial_year: !!selectedYear
					? selectedYear.value.replaceAll(' ', '')
					: '',
				isSipPayoutWidget: [
					1931, 148, 220, 222, 1932, 1933, 1934, 1935,
				].includes(queryFilters?.selectedSubApplication?.value)
					? true
					: false,
			}
		}
		// Ver/Role Summary - populate data in filters
		else if (
			!!queryFilters.selectedSubApplication &&
			queryFilters.selectedSubApplication.value === 222 &&
			flag !== 0
		) {
			params = {
				...params,
				flag: flag,
				workforce_tag_id: queryFilters?.selectedSipSegment?.value,
				vertical_tag_id: 0,
				cluster_id: 0,
				period_type_id: queryFilters?.selectedPeriod?.value || 1,
				financial_year: !!selectedYear
					? selectedYear.value.replaceAll(' ', '')
					: '',
				asset_type_id: 0,
				asset_id:
					queryFilters?.selectedCreator?.value !== 0
						? queryFilters?.selectedCreator?.asset_id
						: authData.asset_id,
			}
		}
		return params
	}

	function fetchActivityCategoryParams(flag = 0) {
		const {
			selectedBuilding: buildingId,
			selectedWorkforce: workforceId,
			selectedCreator: creatorId,
			selectedSegment: segmentId,
			selectedCategory: categoryId,
			selectedFamily: familyId,
		} = queryFilters

		return {
			flag,
			building_id: buildingId && buildingId.value,
			creator_id: creatorId && creatorId.value,
			category_id: categoryId && categoryId.value,
			segment_id: segmentId && segmentId.value,
			family_id: familyId && familyId.value,
			workforce_id: workforceId && workforceId.value,
		}
	}

	function loadStaticOptions() {
		!!filterList &&
			filterList.length &&
			filterList.map(filterItem => {
				if (!!filterItem.filter_inline_data) {
					let optionList = JSON.parse(filterItem.filter_inline_data)
					if (
						queryFilters.selectedSubApplication?.value === 1778 &&
						filterItem.tag_type_filter_options === 'periodTypeList'
					) {
						// optionList.splice(1, 1);
						handleFilterOptionChange('selectedPeriodType', optionList[0])
					}
					setFilterOptions(filterItem.tag_type_filter_options, optionList)
					setQueryFilterValue(filterItem.tag_type_filter_name, optionList[0])
				}
				return null
			})
	}

	function loadWorkflows() {
		if (!!filterList) {
			filterList.map(item => {
				if (item.filter_id === FILTER_FLAG.WORKFLOW) {
					loadWorkEfficiencyWorkflow(fetchOptionsParams(FILTER_FLAG.WORKFLOW), {
						onSuccess: onFilterOptionLoadSuccess,
					})
				} else if (item.filter_id === FILTER_FLAG.INDUSTRY) {
					loadWorkEfficiencyIndustry(fetchOptionsParams(FILTER_FLAG.INDUSTRY), {
						onSuccess: onFilterOptionLoadSuccess,
					})
				} else if (item.filter_id === FILTER_FLAG.CUSTOMER) {
					loadWorkEfficiencyCustomer(fetchOptionsParams(FILTER_FLAG.CUSTOMER), {
						onSuccess: onFilterOptionLoadSuccess,
					})
				}
			})
		}
	}

	function fetchKpiTypesParams() {
		return {
			widget_type_category_id: !!queryFilters.selectedKPICategory
				? queryFilters.selectedKPICategory.value
				: 0,
			asset_type_id: 0,
			flag: 11,
			customer_account_type_id: 0,
			widget_type_level_id: 0,
			widget_type_timeline_id: !!queryFilters.selectedPeriodType
				? queryFilters.selectedPeriodType.value
				: 0,
			period_start_datetime: '',
			period_end_datetime: '',
			workforce_tag_id: !!queryFilters.selectedWorkforceTag
				? queryFilters.selectedWorkforceTag.value !== 0
					? queryFilters.selectedWorkforceTag.value
					: authData.workforce_tag_id
				: 0,
			product_id: !!queryFilters.selectedKPIProduct
				? queryFilters.selectedKPIProduct.value
				: 0,
		}
	}

	const onDebounceFilterOptionSearch = useCallback(
		debounce(search => {
			loadCampaign(fetchOptionsParams(FILTER_FLAG.CAMPAIGN, search), {
				onSuccess: onFilterOptionLoadSuccess,
			})
			loadCreator(fetchOptionsParams(FILTER_FLAG.CREATOR, search), {
				onSuccess: onFilterOptionLoadSuccess,
			})
		}, 500)
	)

	function handleFilterOptionChange(filterName, selectedOption) {
		if (
			filterName === 'selectedApplication' ||
			filterName === 'selectedSubApplication'
		) {
			dashboardDispatch({
				type: dashboardConst.ADD_ACTIVE_FILTER_OPTION,
				activeFilterOption: { value: 0, label: 'All' },
			})
		}
		if (filterName === 'selectedSubApplication') {
			if (selectedOption.value !== 111) {
				const { opportunityList } = filterOptions
				setQueryFilterValue(
					'selectedOpportunity',
					!!opportunityList && opportunityList[0]
				)
			}
		} else if (filterName === 'selectedDateType') {
			if (filterOptions.statusList) {
				if ([1, 3].includes(selectedOption.value)) {
					setQueryFilterValue('selectedStatus', filterOptions.statusList[0])
				} else {
					setQueryFilterValue('selectedStatus', filterOptions.statusList[1])
				}
			}
			setQueryFilterValue('selectedTimescale', filterOptions.timeScaleList[0])
			setQueryFilterValue(
				'selectedSubstatus',
				!!filterOptions.subStatusList ? filterOptions.subStatusList[0] : null
			)
		} else if (filterName === 'selectedStatus') {
			setQueryFilterValue(
				'selectedSubstatus',
				!!filterOptions.subStatusList ? filterOptions.subStatusList[0] : null
			)
		} else if (filterName === 'selectedTimescale') {
			assignTimeScaleChanges(selectedOption)
		} else if (filterName === 'startDateTime') {
			if (
				moment(selectedOption)
					.startOf('day')
					.isBefore(
						moment(queryFilters.endDateTime)
							.subtract(dataViewPeriod, 'months')
							.startOf('day')
					)
			) {
				toast({
					description: `Date range can not exceed more than ${dataViewPeriod} months`,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top',
				})

				selectedOption = moment(queryFilters.endDateTime)
					.subtract(dataViewPeriod, 'months')
					.toDate()
			}

			if (moment(queryFilters.endDateTime).isBefore(selectedOption)) {
				setQueryFilterValue('endDateTime', selectedOption)
			}
		} else if (filterName === 'endDateTime') {
			if (
				moment(queryFilters.startDateTime)
					.startOf('day')
					.isBefore(
						moment(selectedOption)
							.subtract(dataViewPeriod, 'months')
							.startOf('day')
					)
			) {
				toast({
					description: `Date range can not exceed more than ${dataViewPeriod} months`,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top',
				})
				let startDateTime = moment(selectedOption)
					.subtract(dataViewPeriod, 'months')
					.toDate()
				setQueryFilterValue('startDateTime', startDateTime)
			}
			if (moment(queryFilters.startDateTime).isAfter(selectedOption)) {
				setQueryFilterValue(
					'startDateTime',
					getStartDateOfMonth(selectedOption)
				)
			}
		} else if (filterName === 'selectedWorkforceTag') {
			filterList?.forEach(filter => {
				if (
					[
						'selectedPeriodType',
						'selectedPeriod',
						'selectedKPICategory',
						'selectedKPIType',
						'selectedYear',
						'selectedKPIProduct',
						'selectedRole',
						'selectedTagType1',
						'selectedTag1',
						'selectedBuilding',
						'selectedWorkforce',
					].includes(filter.tag_type_filter_name)
				) {
					setQueryFilterValue(
						filter.tag_type_filter_name,
						filterOptions[filter.tag_type_filter_options]?.[0]
					)
				}
			})
		} else if (
			filterName === 'selectedKpiProduct' ||
			filterName === 'selectedKpiCategory'
		) {
			let opt = { label: locale['All'], value: 0 }
			setQueryFilterValue('selectedKpiType', opt)
		} else if (filterName === 'selectedPeriodType') {
			if (selectedOption.value === 17 || selectedOption.value === 8) {
				setQueryFilterValue('selectedPeriod', null)
			} else if (selectedOption.value === 14) {
				setQueryFilterValue('selectedPeriod', HALF_YEARLY[0])
			} else if (selectedOption.value === 7) {
				setQueryFilterValue('selectedPeriod', QUATER[0])
			} else {
				setQueryFilterValue('selectedPeriod', MONTHS[0])
			}
		} else if (filterName === 'selectedCreator') {
			dashboardDispatch({
				type: dashboardConst.ADD_ACTIVE_FILTER_OPTION,
				activeFilterOption: selectedOption,
			})
		}
		setQueryFilterValue(filterName, selectedOption)
		// if (widgetView === WIDGET_WORKSPACE_MODE.WIDGET_DATA) {
		// 	dashboardDispatch({
		// 		type: dashboardConst.UPDATE_WIDGET_DATA,
		// 	})
		// }
	}

	const assignTimeScaleChanges = selectedOption => {
		let startDate, endDate
		const today = getCurrentDate()
		if (selectedOption.value !== 0) {
			//Current Month
			if (selectedOption.value === 1) {
				startDate = getStartDateOfMonth()
				endDate = getEndOfDay()
				if (queryFilters.selectedDateType?.value === 3) {
					endDate = getEndDateOfMonth()
				}
			}
			//Current Quarter
			if (selectedOption.value === 2) {
				startDate = getStartofCurrentQuater()
				endDate = getEndOfDay()
				if (queryFilters.selectedDateType?.value === 3) {
					endDate = getEndofCurrentQuater()
				}
			}
			//Last month
			if (selectedOption.value === 3) {
				startDate = getStartOfPastMonth()
				endDate = getEndOfPastMonth()
			}
			//Last Quarter
			if (selectedOption.value === 4) {
				startDate = getStartofPastQuater()
				endDate = getEndofPastQuater()
			}
			//Next Quarter
			if (selectedOption.value === 5) {
				startDate = getStartofNextQuater()
				endDate = getEndofNextQuater()
			}
			//Next 6 Months
			if (selectedOption.value === 6) {
				startDate = today
				endDate = getEndOfNextMonth(today, 6)
			}
			// Next  Month
			if (selectedOption.value === 7) {
				startDate = getStartOfNextMonth()
				endDate = getEndOfNextMonth(startDate, 1)
			}
			// Realtime
			if (selectedOption.value === 8) {
				startDate = getStartOfRealtime()
				endDate = getEndOfDay()
			}
			setQueryFilterValue('startDateTime', startDate)
			setQueryFilterValue('endDateTime', endDate)
		} else {
			//Custom
			startDate = getStartDateOfMonth()
			endDate = getEndOfDay()
			setQueryFilterValue('startDateTime', startDate)
			setQueryFilterValue('endDateTime', endDate)
		}
	}

	const onClose = () => {
		//setOpenRomsView(false)
		if (
			panelView === view.CATEGORY_SELECTOR ||
			panelView === view.ERROR_VIEW ||
			!!closeDrawer
		) {
			setOpenRomsView(false)
			setCloseDrawer(false)
			setpanelView(view.CATEGORY_SELECTOR)
		} else {
			//onToggleConfirm()
			setpanelView(view.CATEGORY_SELECTOR)
		}
	}
	const onOpen = () => {
		setOpenRomsView(true)
	}

	let tataPlayAccess = ![2103].includes(
		queryFilters?.selectedSubApplication?.value
	)
	return (
		<Box p={2}>
			{queryFilters?.selectedSubApplication?.add_new_file_enabled === 1 && (
				<Button
					className={`action-panel-add-new-file-button`}
					id={`action-panel-btn-add-new-file`}
					w='10%'
					h='35px'
					marginLeft='90%'
					marginBottom='15px'
					fontSize='small'
					leftIcon={<Icon as={BsPlus} w={6} h={6} />}
					variant='filled'
					bg={localStorage.getItem('color')}
					color='white'
					borderRadius={6}
					fontWeight='400'
					boxShadow='md'
					textTransform='capitalize'
					onClick={() => {
						//   eventLabelTrack({
						//     categoryId: 0,
						//     actionId: 0,
						//     label: locale['Open View For Adding New File'],
						//   })
						//   dataLayerTagManager('button_click', {
						//     viewName: 'Home',
						//     buttonName: 'Add New File',
						//   })
						onOpen()
					}}
				>
					{locale['Add New']}
				</Button>
			)}
			<SimpleGrid
				columns={6}
				spacingX={4}
				spacingY={2}
				minChildWidth='160px'
				gridTemplateColumns={
					!!isMobileView ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr'
				}
			>
				{statusFilters === 'loading' ? (
					[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
						<Skeleton key={i} height='70px' />
					))
				) : (
					<>
						{filterOptions.applicationList &&
						filterOptions.applicationList[0]?.isAppVisible === 1 &&
						tataPlayAccess ? (
							// <Center>
							<FilterItem
								key={0}
								label={
									!!filterOptions.applicationList &&
									filterOptions.applicationList[0]?.tag_label
								}
								options={filterOptions.applicationList}
								filterName='selectedApplication'
								handleFilterChange={handleFilterOptionChange}
							/>
						) : (
							// </Center>
							''
						)}
						{/* <Center> */}
						<FilterItem
							key={20}
							label={
								!tataPlayAccess
									? 'Account'
									: !!filterOptions.applicationList &&
									  filterOptions.applicationList[0]?.tag_type_label
							}
							options={filterOptions.subApplicationList}
							filterName='selectedSubApplication'
							handleFilterChange={handleFilterOptionChange}
						/>
						{/* </Center> */}
						{!!filterList && filterList.length
							? filterList.map((filter, i) => {
									const {
										APPLICATION,
										FORM,
										FIELD,
										PROCESS,
										TIMELINE,
										START_DATE,
										END_DATE,
										CAMPAIGN,
										CREATOR,
									} = FILTER_ID
									if (
										![APPLICATION, FORM, FIELD, PROCESS, TIMELINE].includes(
											filter.filter_id
										)
									) {
										return (
											<Fragment key={filter.filter_id}>
												<FilterItem
													key={filter.filter_id}
													id={filter.filter_id}
													label={filter.tag_type_filter_label}
													options={
														filterOptions[filter.tag_type_filter_options]
													}
													onFieldSearch={
														[CAMPAIGN].includes(filter.filter_id) ||
														[CREATOR].includes(filter.filter_id)
															? onDebounceFilterOptionSearch
															: null
													}
													filterName={filter.tag_type_filter_name}
													isDate={[START_DATE, END_DATE].includes(
														filter.filter_id
													)}
													handleFilterChange={handleFilterOptionChange}
													isDisabled={checkFieldDisabled(
														isAccess,
														queryFilters,
														filter.filter_id,
														authData
													)}
												/>
											</Fragment>
										)
									} else {
										return null
									}
							  })
							: null}
					</>
				)}
			</SimpleGrid>

			<Drawer
				isOpen={isRomsOpen}
				placement='right'
				onClose={onClose}
				size={'xl'}
				// finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton mr={4} mt={1} />
					{/* <DrawerHeader>{activity?.activity_title} </DrawerHeader> */}
					<DrawerBody>
						{/* {view.ORIGIN_FORM === panelView ? null : (
						<IconButton
						zIndex={1}
						position="absolute"
						top={2}
						right={2}
						bg="white"
						borderRadius="md"
						boxShadow="md"
						aria-label="close"
						size="sm"
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						onClick={e => {
							// sectionDetailedTrack({
							// category: GA_CATEGORY_FORM_MANAGEMENT,
							// action: 'Origin Form Panel',
							// label: 'Close',
							// })
							onClose(e)
						}}
						icon={<Icon as={MdClose} w={6} h={6} />}
						/>
					)} */}
						{panelView === view.CATEGORY_SELECTOR ? (
							<FormPanel
								onFormSelect={data => {
									setpanelView(view.ORIGIN_FORM)
									setSelectedForm(data)
								}}
								setCloseDrawer={setCloseDrawer}
								tag_type_id={queryFilters?.selectedSubApplication?.value}
								from={from}
							/>
						) : panelView === view.ERROR_VIEW ? (
							<Box p={4}>
								<Center>
									<Text>
										{' '}
										Opps something went wrong please try after some time!
									</Text>
									<Button
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_FORM_MANAGEMENT,
											// 	action: 'Origin Form Panel',
											// 	label: 'Error Message',
											// })
											setpanelView(view.ORIGIN_FORM)
										}}
									>
										Okay
									</Button>
								</Center>
							</Box>
						) : (
							<SmartFormContextProvider>
								<SmartFormPanel
									isOrigin={true}
									onClose={d => {
										if (d && d.activity_id) {
											localStorage.setItem(
												'DASHBOARD_ACTIVITY_ID',
												d.activity_id
											)
											onClose(d)
											history.push('/files')
										} else {
											setpanelView(view.CATEGORY_SELECTOR)
										}
									}}
									formData={{ ...selectedForm, activity_id: 0 }}
									handleError={() => {
										setpanelView(view.ERROR_VIEW)
									}}
								/>
							</SmartFormContextProvider>
						)}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	)
}

export default FilterView
