import { useEffect, useState } from 'react'
import { useGetTeamMembers } from './../../hooks/team.hooks'
import { useAuthContext } from './../../context/auth.context'
import { useLanguageContext } from './../../context/locale.context'
import CustomMenu from './../../components/menu/index'
import { MdSort } from 'react-icons/md'
import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react'
import ReporteeDisplay from './reporteeDisplay'
import { useLoadBotBetaAssetMapping } from './../../hooks/asset.hooks'
import AssetARPScoreDisplay from './assetARPScoreDisplay'

const ReporteeArpScores = () => {
  const {
    mutate: loadTeamMembers,
    isLoading: loadingTeamMembers,
    data: dataTeamMembers,
  } = useGetTeamMembers()

  const {
    mutate: loadBotBetaAssetMapping,
    data: botBetaAssetData,
    isLoading: loadingBotBetaAsset,
  } = useLoadBotBetaAssetMapping()

  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()

  const TeamFilterList = [
    {
      label: locale['Direct'],
      key: 'direct',
      value: 1,
    },
    {
      label: locale['Indirect'],
      key: 'Indirect',
      value: 2,
    },
  ]

  const headers = [
    [
      'S. No',
      locale['Asset Name'],
      locale['Segment'],
      locale['Circle'],
      locale['Last Seen'],
    ],
    [
      'S.No',
      'Bot Name',
      'Status Transactions Count',
      locale['BPI'],
      locale['RPI'],
      locale['CPI'],
      locale['IOF'],
    ],
  ]

  const [selectedFilter, setSelectedFilter] = useState(TeamFilterList[0])
  const [selectedReportee, setSelectedReportee] = useState(null)

  useEffect(() => {
    loadTeamMembers({
      page_start: 0,
      page_limit: 500,
      start_from: 0,
      limit_value: 500,
      flag: selectedFilter.value,
      user_asset_id: authData.asset_id,
    })
  }, [loadTeamMembers, authData, selectedFilter])

  const selectReportee = reportee => {
    loadBotBetaAssetMapping({
      target_asset_id: reportee.asset_id,
      flag: 2,
      start_from: 0,
      limit_value: 50,
      bot_id: 0,
    })
    setSelectedReportee(reportee)
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        {selectedReportee && (
          <>
            <Tag
              size="sm"
              borderRadius="full"
              variant="outline"
              colorScheme={localStorage.getItem('color')}
            >
              <TagLabel>{selectedReportee.operating_asset_first_name}</TagLabel>
              <TagCloseButton onClick={() => setSelectedReportee(null)} />
            </Tag>
          </>
        )}
        <CustomMenu
          optionList={TeamFilterList}
          selected={selectedFilter}
          isFilter={true}
          mx={2}
          ml="auto"
          onSelect={item => {
            setSelectedReportee(null)
            setSelectedFilter(item)
          }}
          icon={MdSort}
        />
      </div>
      {selectedReportee ? (
        <AssetARPScoreDisplay
          headers={headers[1]}
          data={botBetaAssetData}
          isLoading={loadingBotBetaAsset}
        />
      ) : (
        <ReporteeDisplay
          headers={headers[0]}
          data={dataTeamMembers}
          onSelect={selectReportee}
          isLoading={loadingTeamMembers}
        />
      )}
    </>
  )
}

export default ReporteeArpScores
