import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  TableCaption,
  Button,
} from '@chakra-ui/react'
import { checkBetaDataExists } from '../../utils/arp.util'
import { useLanguageContext } from './../../context/locale.context'

const AssetARPScoreBreakdown = ({ isOpen, onClose, botBetaValueData }) => {
  const {
    state: { locale },
  } = useLanguageContext()

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(2px)" />
        <ModalContent maxW="70em" w="fit-content">
          <ModalHeader>Score Breakdown</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ display: 'flex', marginTop: '1em' }}>
              <TableContainer mr={5} w="30em">
                <Table variant="simple">
                  <TableCaption>
                    {locale['BPI']}
                    {checkBetaDataExists(botBetaValueData, 'computation_bpi')}
                  </TableCaption>
                  <Tbody>
                    <Tr>
                      <Td>{locale['Qualification']}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'bpi_qualification'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{locale['Relevant Work Experience']}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'bpi_workex_relevant'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{locale['Total Work Experience']}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'bpi_workex_total'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{locale['Relevant Certificates']}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'bpi_certificates_relevant'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{locale['Total Certificates']}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'bpi_certificates_total'
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <TableContainer w="30em">
                <Table>
                  <TableCaption>
                    {locale['RPI']}
                    {checkBetaDataExists(botBetaValueData, 'computation_rpi')}
                  </TableCaption>
                  <Tbody>
                    <Tr>
                      <Td>Total Customer Exposure</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_exposure_customer'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Industry Exposure</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_exposure_industry'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Deliveries Ongoing</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_deliveries_ongoing'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Deliveries Completed</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_deliveries_completed'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Lead Replaced</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_deliveries_lead_replaced'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Deliveries Completed Without Rollbacks</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_deliveries_completed_without_rollbacks'
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total Deliveries Completed Ontime</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'tot_rpi_deliveries_completed_ontime'
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} colorScheme={localStorage.getItem('color')} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AssetARPScoreBreakdown
