import {
  Table,
  Thead,
  Tbody,
  Center,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useLanguageContext } from '../../context/locale.context'
import { checkBetaDataExists } from '../../utils/arp.util'
import AssetARPScoreBreakdown from './assetARPScoreBreakdown'

const AssetARPScoreDisplay = ({ headers, data, isLoading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedData, setSelectedData] = useState({})
  const {
    state: { locale },
  } = useLanguageContext()

  if (isLoading)
    return (
      <Center p="10">
        <Spinner size="lg" color="brand.900" />
      </Center>
    )

  return (
    <>
      <AssetARPScoreBreakdown
        isOpen={isOpen}
        onClose={onClose}
        botBetaValueData={selectedData}
      />
      {data?.length > 0 ? (
        <TableContainer
          maxH="70vh"
          overflowY="scroll"
          overflowX="visible"
          pr={5}
          mt={5}
          sx={{
            '::-webkit-scrollbar': {
              width: '3px',
            },
            '::-webkit-scrollbar-thumb': {
              outline: `1px solid ${localStorage.getItem('color')}`,
              borderRadius: '50px',
              border: 'solid 3px transparent',
              backgroundClip: 'content-box',
              WebkitBackgroundClip: 'content-box',
            },
          }}
        >
          <Table variant="stickyHeaders">
            <Thead>
              <Tr>
                {headers.map((item, i) => {
                  return <Th key={i}>{item}</Th>
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((bot, index) => {
                const botBetaValueData = JSON.parse(bot.bot_beta_value_data)
                return (
                  <>
                    <Tr
                      key={bot.bot_id}
                      cursor="pointer"
                      onClick={() => {
                        setSelectedData(botBetaValueData)
                        onOpen()
                      }}
                      _hover={{
                        fontWeight: '600',
                      }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{bot.bot_name}</Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'computation_transactions_data_size'
                        )}
                      </Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'computation_bpi'
                        )}
                      </Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'computation_rpi'
                        )}
                      </Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'computation_cpi'
                        )}
                      </Td>
                      <Td>
                        {checkBetaDataExists(
                          botBetaValueData,
                          'computation_iof'
                        )}
                      </Td>
                    </Tr>
                  </>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Center>
          <h5>{locale["Asset Doesn't Have Any ARP Scores"]}</h5>
        </Center>
      )}
    </>
  )
}

export default AssetARPScoreDisplay
